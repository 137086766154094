<template>
  <div class="component-warp">
    <div class="table-wrap">
      <div class="filters">
        <div class="input-item">
          <a-input
            allowClear
            :placeholder="$t('enterprise.table.account', 2)"
            v-model:value="queryParam.account"
          >
            <!-- 请输入账号 -->
            <template #prefix>
              <SearchOutlined style="color: #999" />
            </template>
          </a-input>
        </div>
        <div class="input-item">
          <a-input
            allowClear
            :placeholder="$t('enterprise.table.real_name', 2)"
            v-model:value="queryParam.realName"
          >
            <!-- 请输入姓名 -->
            <template #prefix>
              <SearchOutlined style="color: #999" />
            </template>
          </a-input>
        </div>
        <div class="input-item">
          <a-select
            allowClear
            :placeholder="$t('enterprise.table.state', 2)"
            v-model:value="queryParam.status"
            style="width: 100%"
          >
            <!-- 请选择状态 -->
            <a-select-option :value="1">{{
              $t("enterprise.info_state", 1)
            }}</a-select-option>
            <!-- 正常 -->
            <a-select-option :value="8">{{
              $t("enterprise.info_state", 2)
            }}</a-select-option>
            <!-- 停用 -->
          </a-select>
        </div>
        <a-button type="primary" @click="handleSearch(queryParam)">{{
          $t("XB_Search")
        }}</a-button>
        <!-- 搜索 -->
        <a-button style="margin-left: 10px" @click="handleSearch()">
          {{ $t("CM_Btnreset") }}
          <!-- 重置 -->
        </a-button>
      </div>
      <a-table
        :columns="columns"
        :data-source="state.dataSource"
        rowKey="userId"
        :bordered="false"
        :pagination="pagination"
        :loading="state.loading"
        @change="handleTableChange"
      >
        <template #realName="{ record }">
          <div class="table-td-box account">
            <div class="portrait">
              <img :src="record.portrait" alt="portrait" />
            </div>
            <div class="text">
              <p class="name">
                <a-tooltip placement="topLeft">
                  <template #title
                    ><OpenData type="userName" :openid="record.realName"
                  /></template>
                  <OpenData type="userName" :openid="record.realName" />
                </a-tooltip>
              </p>
            </div>
          </div>
        </template>
        <template #account="{ text }">
          <div class="table-td-box">
            <a-tooltip placement="topLeft">
              <template #title>{{ text }}</template>
              {{ text }}
            </a-tooltip>
          </div>
        </template>
        <template #departmentName="{ text }">
          <div class="table-td-box">
            <div>
              <a-tooltip placement="topLeft">
                <template #title
                  ><OpenData type="departmentName" :openid="text"
                /></template>
                <OpenData type="departmentName" :openid="text" />
              </a-tooltip>
            </div>
          </div>
        </template>
        <template #status="{ text }">
          <div class="table-td-box">
            <span v-if="text == 1">{{ $t("enterprise.info_state", 1) }}</span>
            <!-- 正常 -->
            <span v-else-if="text == 8">{{
              $t("enterprise.info_state", 2)
            }}</span>
            <!-- 停用 -->
          </div>
        </template>
        <template #createTime="{ text }">
          {{ dateFormat(text) }}
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, toRaw } from "vue";
import { enterpriseMemberList } from "@/api/user";
import { useFetchData } from "@/utils/useFetchData";
import { dateFormat } from "@/utils/tools";
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const stateData = reactive({
      columns: [
        {
          title: $t("enterprise.table.real_name", 1),
          // title: "姓名",
          dataIndex: "realName",
          ellipsis: true,
          width: 180,
          slots: { customRender: "realName" },
        },
        {
          title: $t("enterprise.table.account", 1),
          // title: "账号",
          dataIndex: "account",
          ellipsis: true,
          width: 180,
          slots: { customRender: "account" },
        },
        {
          title: $t("enterprise.table.unit"),
          // title: "单位",
          dataIndex: "departmentName",
          ellipsis: true,
          width: 180,
          slots: { customRender: "departmentName" },
        },
        {
          title: $t("enterprise.table.create_time"),
          // title: "创建时间",
          dataIndex: "createTime",
          width: 180,
          slots: { customRender: "createTime" },
        },
        {
          title: $t("enterprise.table.state", 1),
          // title: "状态",
          dataIndex: "status",
          width: 110,
          slots: { customRender: "status" },
        },
      ],
    });

    const getList = (params) => {
      let form = JSON.parse(JSON.stringify(queryParam));
      form.page = params.current;
      form.pageSize = params.pageSize;

      return enterpriseMemberList(form).then((res) => {
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };

    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 10,
      tableSize: "middle", // 'default' | 'middle' | 'small'
      stripe: false,
    });

    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      if (sorter.field != undefined) {
        if (
          sorter.field != queryParam.sortKey ||
          sorter.order != queryParam.sort
        ) {
          current = 1;
        }
      }
      queryParam.sortKey = sorter.field;
      queryParam.sort = sorter.order;

      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });

      reload();
    };
    const { total, pageSize, current } = toRefs(state);

    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["10", "30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    let queryParam = reactive({
      account: "",
      realName: "",
    });

    const handleSearch = (searchModel) => {
      if (searchModel) {
        queryParam = searchModel;
      } else {
        queryParam.account = "";
        queryParam.realName = "";
        delete queryParam.status;
      }
      setPageInfo({ current: 1, ...toRaw(queryParam) });
      reload();
    };

    return {
      dateFormat,
      ...toRefs(stateData),
      state,
      pagination,
      handleTableChange,
      queryParam,
      handleSearch,
    };
  },
};
</script>

<style lang="less" scoped>
.component-warp {
  min-height: 800px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  padding: 16px 32px 32px 32px;
}
.table-wrap {
  .filters {
    .mixinFlex(flex-start);
    padding-bottom: 10px;
    .input-item {
      width: 220px;
      margin: 0 20px 6px 0;
    }
  }
  ::v-deep(td) {
    vertical-align: middle !important;
  }
  ::v-deep(.ant-table-pagination.ant-pagination) {
    float: none;
    text-align: center;
  }
  .table-td-box {
    min-height: 52px;
    .mixinFlex(flex-start; center);
    &.account {
      .mixinFlex(space-between; center);
      .portrait {
        .mixinImgWrap(40px; 40px);
        border-radius: 50%;
      }
      .text {
        width: calc(100% - 60px);
        p {
          margin: 0;
          font-size: 14px;
          color: #666;
          &.name {
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>
